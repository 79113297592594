.toaster {
	--font-family: var(--typography-btn-family);
	--font-size: var(--typography-btn-size);
	--font-weight: var(--typography-btn-weight);
	--letter-spacing: var(--typography-btn-letter-spacing);
	--line-height: var(--typography-btn-line-height);

	align-items: flex-end;
	background-color: var(--color-neutral-black);
	border-radius: var(--spacing-4xs);
	bottom: calc(var(--spacing-xl) + var(--footer-ad-height));
	color: var(--color-neutral-white);
	display: flex;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	height: 40px;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding: var(--spacing-2xs);
	position: fixed;
	right: var(--spacing-xl);
	z-index: 1000;

	& .message {
		max-width: 50ch;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@media (--bp-large) {

		& .message {
			max-width: 100ch;
		}
	}

	&.info {
		background-color: var(--color-util-yellow);
		color: var(--color-neutral-black);

		& .separator {
			color: var(--color-neutral-dark-40);
		}
	}

	&.error {
		background-color: var(--color-util-red);
	}

	&.success {
		background-color: var(--color-util-green);
	}

	& svg {
		fill: currentColor;
		height: 1rem;
		width: 1rem;
	}

	& .separator {
		color: var(--color-neutral-light-40);
		height: 1.2rem;
		width: 1.5rem;
	}

	& button {
		background-color: transparent;
		border: none;
		color: inherit;
		cursor: pointer;
		line-height: 0;
		padding: 0;
		position: relative;
	}
}
