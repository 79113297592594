.previewIndicator {
	align-items: center;
	background-color: var(--color-neutral-black);
	border-radius: var(--radius-m);
	bottom: var(--spacing-m);
	box-shadow: var(--nav-shadow);
	color: var(--color-neutral-white);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-s);
	justify-content: center;
	padding: var(--spacing-xs);
	position: fixed;
	right: var(--spacing-s);
	z-index: 100;
}
