.skipToMainContent {
	left: -9999px;
	opacity: 0;
	position: absolute;
	z-index: 999;

	&:focus {
		left: var(--spacing-xs);
		opacity: 1;
		top: var(--spacing-xs);
	}
}
